import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { loadReportName, clearReportName, loadReportData, clearReportData, loadReportFilter, loadReportFilterMetaData, loadReportGenerateDate } from './report.actions';

export interface reportState {
    name: string | null;
    reportData: any | null;
    reportFilter: any | null;
    filterMetaData: any | null;
    generateDate: string | null;
}

export const initialState: reportState = {
    name: null,
    reportData: null,
    reportFilter: null,
    filterMetaData: null,
    generateDate: null,
};

export const reportFeature = createFeature({
    name: 'report',
    reducer: createReducer(
        initialState,
        on(loadReportName, (state, { reportName }) => ({ ...state, name: reportName })),
        on(loadReportData, (state, { reportData }) => ({ ...state, reportData: reportData })),
        on(loadReportFilter, (state, { reportFilter }) => ({ ...state, reportFilter: reportFilter })),
        on(loadReportFilterMetaData, (state, { filterMetaData }) => ({ ...state, filterMetaData: filterMetaData })),
        on(loadReportGenerateDate, (state, { generateDate }) => ({ ...state, generateDate: generateDate })),

        on(clearReportName, (state) => ({ ...state, name: null })),
        on(clearReportData, (state) => ({ ...state, ...initialState }))
    ),
});

export const { name, reducer, selectReportState } = reportFeature;

export const selectReportName = createSelector(selectReportState, (state) => state.name);
export const selectReportData = createSelector(selectReportState, (state) => state.reportData);
export const selectReportFilter = createSelector(selectReportState, (state) => state.reportFilter);
export const selectReportFilterMetaData = createSelector(selectReportState, (state) => state.filterMetaData);
export const selectReportGenerateDate = createSelector(selectReportState, (state) => state.generateDate);
