import { createAction, props } from '@ngrx/store';

export const loadReportName = createAction('[Report] Load Report Name Success', props<{ reportName: string }>());
export const loadReportData = createAction(' [Report] Load Report Data Success', props<{ reportData: any }>());
export const loadReportFilter = createAction('[Report] Load Report Filter Success', props<{ reportFilter: any }>());
export const loadReportFilterMetaData = createAction('[Report] Load Report Filter MetaData Success', props<{ filterMetaData:any }>())
export const loadReportGenerateDate = createAction('[Report] Load Report Generate Date Success', props<{ generateDate:any }>())

export const clearReportName = createAction('[Report] Clear Report Name');
export const clearReportData = createAction('[Report] Clear Report Data');
export const clearReportGenerateDate = createAction('[Report] Clear Report Generate Date');