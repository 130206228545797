import { Injectable } from '@angular/core';
import { Observable, delay, take, } from 'rxjs';
import { CommonService } from '../../../shared/services/common.service';
import { environment } from '../../../../environments/environment';
import { API_ENDPOINTS } from '../../../core/services/api.service';
import { Store } from '@ngrx/store';
import { reportState, selectReportGenerateDate } from '../state/report.state';
import { loadReportData, loadReportFilter, loadReportFilterMetaData } from '../state/report.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GenericConfirmationDialogComponent } from '../../../shared/components/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { ConfirmModel, ReportType } from '../../../shared/types';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DateTime } from 'luxon';
import { UiService } from '../../../shared/services/ui.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public isViewReport:boolean = false;
  public isDownload:boolean = false;
  constructor(private commonSvc:CommonService, private store:Store<reportState>, private uiSvc: UiService) { }

  fetchAssetByLocation(payload:any) : Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.ASSETS.LIST_BY_LOCATION, payload)
  }
  fetchSensorById(payload:any) : Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.DEVICE_MANAGEMENT.SENSOR.LIST_BY_ASSET_ID, payload)
  }
  fetchMyReports(payload:any) : Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.REPORTS.MY_REPORTS, payload)
  }
  fetchAllReports(payload:any) : Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.REPORTS.ALL_REPORTS, payload)
  }
  fetchScheduleReports(payload: any): Observable<any> {
    return this.commonSvc.httpSvc.post(environment.reportsBaseUrl + API_ENDPOINTS.REPORTS.SCHEDULE_REPORTS, payload)
  }
  deleteScheduleReport(payload: any): Observable<any> {
    return this.commonSvc.httpSvc.post(environment.reportsBaseUrl + API_ENDPOINTS.REPORTS.DELETE_SCHEDULE_REPORT, payload)
  }
  public viewReportHandler(item: any) {
    this.isViewReport = true;
    let reportType = item['report_name'].split('-')[0];
    this.generateReport(item?.filters, reportType).pipe(untilDestroyed(this),delay(500)).subscribe({
      next: (res) => {
        if (res?.status === 200) {
          this.isViewReport = false;
          this.store.dispatch(loadReportFilter({ reportFilter: item?.filters }));
          this.store.dispatch(loadReportData({ reportData: res?.data }));
          this.store.dispatch(loadReportFilterMetaData({ filterMetaData: item }));
          this.routeOnReportType(reportType);
        }
        if(res?.status !== 200) {
          this.isViewReport = false;
          this.commonSvc.message.error(res?.message, { nzDuration: 3000 });
        }
      },
      error: (err) => {
        this.isViewReport = false;
      }
    })
  }
  generateReport(payload:any, reportType: string) : Observable<any> {
    let URL: string;
    switch (reportType) {
      case 'Device Report':
      case 'device_report':
        URL = API_ENDPOINTS.REPORTS.DEVICE_REPORT;
        break;
      case 'Asset Summary':
      case 'asset_summary_report':
        URL = API_ENDPOINTS.REPORTS.ASSET_REPORT;
        break;
      case 'Daily Summary Report':
      case 'daily_summary_report':
        URL = API_ENDPOINTS.REPORTS.DAILY_REPORT;
        break;
      case 'Incident Summary Report':
      case 'incident_summary_report':
        URL = API_ENDPOINTS.REPORTS.INCIDENT_REPORT;
        break;
      case 'Audit Log':
      case 'audit_log_report':
        URL = API_ENDPOINTS.REPORTS.AUDIT_REPORT;
        break;
      // case 'Compliance Log v2':
      //   URL = API_ENDPOINTS.REPORTS.DEVICE_SUMMARY;
      //   break;
      default:
        throw new Error(`Unsupported report type: ${reportType}`);
    }
    return this.commonSvc.httpSvc.post(environment.reportsBaseUrl + URL, payload)
  }
  routeOnReportType(report_type: string) {
    switch (report_type) {
      case 'Device Report':
      case 'device_report':
        this.commonSvc.router.navigate([`/reports/device-report`]);
        break;
      case 'Asset Summary':
      case 'asset_summary_report':
        this.commonSvc.router.navigate([`/reports/asset-summary`]);
        break;
      case 'Daily Summary Report':
      case 'daily_summary_report':
        this.commonSvc.router.navigate([`/reports/daily-report`]);
        break;
      case 'Incident Summary Report':
      case 'incident_summary_report':
        this.commonSvc.router.navigate([`/reports/incident-report`]);
        break;
      case 'Audit Log':
      case 'audit_log_report':
        this.commonSvc.router.navigate([`/reports/audit-report`]);
        break;
      // case 'Compliance Log v2':
      //   this.commonSvc.router.navigate([`/reports/compliance-report`]);
      //   break;
    }
  }
  public reportEventHandler(action: 'save' | 'sign' | 'submit' | 'download', report_type: string, filter: any, filterMetaData?: any): void {
    this.store.select(selectReportGenerateDate).pipe(take(1)).subscribe({
      next: (generateDate) => {
        if(generateDate) {
          filterMetaData['saved_at'] = generateDate;
        }
      }
    });
    let payload: any = {
      ...this.commonSvc.nLevelPayload,
      user_id: this.commonSvc.user_data.user_id,
      report_type: report_type,
      filters: [filter || filterMetaData?.filters],
      customer_id: this.commonSvc.user_data.customer.customer_id,
    };
    const handleResponse = (res: any, successMessage: string) => {
      if (res?.isVerified === false) {
        this.commonSvc.message.warning('Invalid Password', { nzDuration: 3000 });
        return;
      }
      if (res?.status === 200) {
        this.commonSvc.message.success(successMessage, { nzDuration: 3000 });
        this.store.dispatch(loadReportFilterMetaData({ filterMetaData: res?.data }));
      }
    };
    const signAndSubmitWithModal = (successMessage: string, additionalPayload: any = {}) => {
      this.openPassAndRemarksModal().afterClose.subscribe((value) => {
        if (value?.password && value?.remarks) {
          const finalPayload = { ...payload, ...additionalPayload, password: value.password, remarks: value.remarks };
          this.signAndSubmitObservable(finalPayload).subscribe((res) => handleResponse(res, successMessage));
        }
      });
    };
    const downloadReport = (filterMetaData: any) => {
      const downloadPayload = {
        on_location: filterMetaData?.filters?.location,
        is_download: true,
        report_name: filterMetaData?.report_name,
        ...filterMetaData?.filters,
      };
      this.commonSvc.message.info('Request in progress you will receive an email soon...', { nzDuration: 2000 });
      this.isDownload = true;
      this.generateReport(downloadPayload, report_type).subscribe({
        next: (res: any) => {
          if (res?.status === 200 && res?.data) {
            this.commonSvc.message.success('The report has been successfully delivered to your registered email address!', { nzDuration: 3000 });
            this.isDownload = false;
          }
          if (res?.status !== 200 && res?.data?.length === 0) {
            this.isDownload = false;
            // this.commonSvc.message.error('An error occurred. Please try again after sometimes.', { nzDuration: 3000 });
          }
        },
        error: (err) => {
          // this.commonSvc.message.error('An error occurred. Please try again after sometimes.', { nzDuration: 3000 });
          this.isDownload = false;
        },
      });
    };
    switch (action) {
      case 'save':
        this.uiSvc.saveReportModal().subscribe((reportName: string) => {
          if (reportName) {
            Object.assign(
              payload, {
              is_saved: true,
              is_submit: false,
              is_signed: false,
              saved_at: filterMetaData?.saved_at ? DateTime.fromFormat(filterMetaData?.saved_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss')
            });
            this.signAndSubmitObservable(payload).subscribe((res) => handleResponse(res, 'Report saved successfully!'));
          }
        });
        break;
      case 'sign':
        if (!filterMetaData || Object.keys(filterMetaData).length === 0 || !filterMetaData.is_saved) {
          Object.assign(
            payload, {
            is_saved: true, is_submit: false, is_signed: false,
            saved_at: filterMetaData?.saved_at ? DateTime.fromFormat(filterMetaData?.saved_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss')
          });
          this.signAndSubmitObservable(payload).subscribe((filterMetaData) => {
            handleResponse(filterMetaData, 'Report saved successfully!');
            signAndSubmitWithModal('Report signed successfully!',
              {
                is_saved: true,
                is_signed: true,
                is_submit: false,
                report_name: filterMetaData?.report_name,
                saved_at: filterMetaData?.saved_at ? DateTime.fromFormat(filterMetaData?.saved_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
                sign_at: filterMetaData?.signed_at ? DateTime.fromFormat(filterMetaData?.sign_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss')
              });
          });
        } else {
          signAndSubmitWithModal('Report signed successfully!',
            {
              is_saved: true,
              is_signed: true,
              is_submit: false,
              report_name: filterMetaData?.report_name,
              saved_at: filterMetaData?.saved_at ? DateTime.fromFormat(filterMetaData?.saved_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
              sign_at: filterMetaData?.signed_at ? DateTime.fromFormat(filterMetaData?.sign_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss')
            });
        }
        break;
      case 'submit':
        if (!filterMetaData || Object.keys(filterMetaData).length === 0 || !filterMetaData.is_signed) {
          signAndSubmitWithModal('Report submitted successfully!',
            {
              is_saved: true,
              is_signed: true,
              is_submit: true,
              saved_at: filterMetaData?.saved_at ? DateTime.fromFormat(filterMetaData?.saved_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
              sign_at: filterMetaData?.signed_at ? DateTime.fromFormat(filterMetaData?.sign_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
              submit_at: filterMetaData?.submit_at ? DateTime.fromFormat(filterMetaData?.submit_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
              report_name: filterMetaData?.report_name
            });
        } else if (filterMetaData.is_signed) {
          Object.assign(payload,
            {
              is_saved: true,
              is_signed: true,
              is_submit: true,
              saved_at: filterMetaData?.saved_at ? DateTime.fromFormat(filterMetaData?.saved_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
              sign_at: filterMetaData?.sign_at ? DateTime.fromFormat(filterMetaData?.sign_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
              submit_at: filterMetaData?.submit_at ? DateTime.fromFormat(filterMetaData?.submit_at, "yyyy-MM-dd HH:mm:ss").toUTC().toFormat('yyyy-MM-dd HH:mm:ss') : DateTime.now().toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
              report_name: filterMetaData?.report_name
            })
          this.signAndSubmitObservable(payload).subscribe((res) => handleResponse(res, 'Report submitted successfully!'));
        }
        break;
      case 'download':
        if (!filterMetaData || Object.keys(filterMetaData).length === 0 || !filterMetaData.is_signed) {
          this.commonSvc.message.info('The report must be signed before it can be downloaded!');
        } else {
          downloadReport(filterMetaData);
        }
        break;
    }
  }
  openPassAndRemarksModal() {
    let modal: NzModalRef<GenericConfirmationDialogComponent> = this.commonSvc.modalSvc.create<GenericConfirmationDialogComponent, ConfirmModel>({
      nzTitle: 'Verify password and add remarks!',
      nzContent: GenericConfirmationDialogComponent,
      nzCentered: true,
      nzData: { for: 'REPORTS' },
      nzMaskClosable: false,
      nzClosable: false,
    })
    return modal;
  }
  signAndSubmitObservable(payload: any) : Observable<any> {
    return this.signAndSubmitReport(payload)
  }
  signAndSubmitReport(payload:any):Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.REPORTS.SIGN_AND_SUBMIT_REPORT, payload)
  }
  makeSchedule(payload:any): Observable<any> {
    return  this.commonSvc.httpSvc.post(environment.reportsBaseUrl + API_ENDPOINTS.REPORTS.CREATE_SCHEDULE, payload)
  }
  getReportType(reportName:string) {
    let reportType = '';
    switch (reportName) {
      case 'Audit Log':
        reportType = ReportType.audit;
        break;
      case 'Device Report':
        reportType = ReportType.device;
        break;
      case 'Asset Summary':
        reportType = ReportType.asset;
        break;
      case 'Incident Summary Report':
        reportType = ReportType.incident;
        break;
      case 'Daily Summary Report':
        reportType = ReportType.daily;
        break;
    }
    return reportType
  }
}